import React, {useEffect, useState} from 'react';
import {
  Wrapper,
  Content,
  Footer,
  Header,
  Title,
  Text,
  ButtonStart,
  ItemContainter,
  RegisterContainer,
  StepBar,
  Input,
  Label,
  RegisterButton,
  RegisterSubTitle,
  RegisterTitle,
  FieldContainerColumn,
  FieldContainerRow,
  Select,
  RegisterWrap,
  FooterInfo,
  ErrorMessage,
  RegisterButtonGray,
  TextoCadastro, HeaderDiv
} from './styles';
import logo from '../../assets/logo.png';
import redCheckIcon from '../../assets/redCheckIcon.png';
import goldcheckicon from '../../assets/goldcheckicon.png';
import betfastio from '../../assets/betfastio.png';
import verifedlogo from '../../assets/verifedlogo.png';
import userService from '../../services/user.service';
import {CircularProgress} from "@mui/material";
import activehostedService from "../../services/activehosted.service";

const LandingPage: React.FC = () => {
  const [step, setStep] = React.useState(1);
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [sobrenome, setSobrenome] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [validationError, setValidationError] = React.useState('')
  const [validate, setValidate] = React.useState({
    email: false,
    phone: false,
    cpf: false
  })
  const [loading, setLoading] = React.useState(false);
  const [linkTelegram, setLinkTelegram] = React.useState('https://t.me/+oM_iCZlGbnphYThh');
  const [erro, setErro] = useState("")

  const handleNextStep = async (currentStep: number) => {
    console.log(validate)

    if (currentStep === 2) {
      setStep(3)
    }
  };

  const cpfHandle = (cpf: string) => {
    setValidationError('')
    setCpf(cpf);

    if (cpf) {
      const re = new RegExp(
        '([0-9]{2}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[\\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[-]?[0-9]{2})'
      );
      const ok = re.test(cpf);

      if (cpf != '' && cpf != null && ok) {
        setValidationError('')
        setValidate({...validate, cpf: true})
      }
      else{
        setValidationError('Insira um cpf valido')
        setValidate({...validate, cpf: false})
      }
    }
  };

  const phoneHandle = (phone: string) => {
    setValidationError('')
    setPhone(phone);

    if (phone) {
      // Verificação apenas para telefones Brasileiros
      const re = new RegExp(
        '^\\s*(\\d{2}|\\d{0})[-. ]?(\\d{5}|\\d{4})[-. ]?(\\d{4})[-. ]?\\s*$'
      );
      const ok = re.test(phone);

      if (phone != '' && phone != null && ok) {
        setValidationError('')
        setValidate({...validate, phone: true})
      }
      else{
        setValidationError('Insira um telefone valido')
        setValidate({...validate, phone: false})
      }
    }
  };

  const emailHandle = (emailData: string) => {
    setValidationError('')
    setEmail(emailData);

    if (email) {
      const re = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
      const ok = re.test(emailData);

      if (email !== '' && email != null && ok) {
        setValidationError('')
        setValidate({...validate, email: true})
      }
      else{
        setValidationError('Insira um email valido')
        setValidate({...validate, email: false})
      }
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(e)
    setLoading(true)
    if(new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$').test(email) && new RegExp(
        '^\\s*(\\d{2}|\\d{0})[-. ]?(\\d{5}|\\d{4})[-. ]?(\\d{4})[-. ]?\\s*$'
    ).test(phone)) {
      setErro("")
      activehostedService.CreateContact({
        email: email,
        firstName: nome,
        lastName: "",
        phone: phone,
        listId: 24,
        cpf: cpf
      })
          .then(r => {
            console.log(r)
            setStep(2)
          })
          .catch(e => setStep(2))
    }
    else {
      setErro("Por favor, verifique os campos preenchidos.")
    }
      // userService.CreateUser(nome, sobrenome, email, phone)
      // .then(retorno => {
      //   let result = JSON.parse(retorno.data.result)
      //   if(result.result === false){
      //     setValidationError(result.Mensagem);
      //     setLoading(false);
      //     return
      //   }
      //   console.log(result)
      //   setLinkTelegram(result.result.invite_link)
      //   setStep(2)
      // })
      // .catch(err => {
      //   console.log(err)
      //   setValidationError(err.response.data.message);
      //   console.error(err.response.data.message)
      //   setLoading(false);
      // })
  }

  return (
    <Wrapper>
      <Header>
        <HeaderDiv>
          <img src={logo} alt='Logo' />
          <Title>
            <span>GRUPO DE ALAVANCAGEM DA ORTEGATIPS</span> ESTÁ DE CARA NOVA<br />
          </Title>
          <Text>
            Participe do <span>Grupo de Alavancagem</span> da Empresa com os melhores resultados do Brasil.
            <br/>
          </Text>
          <a href='#middle'>
            <ButtonStart>CADASTRAR</ButtonStart>
          </a>
        </HeaderDiv>
      </Header>

      <Content content>
          <form onSubmit={e => onSubmit(e)}>
            <section>
              <Title content>
                <span>GRUPO DE ALAVANCAGEM  ORTEGATIPS</span>
              </Title>
              {/*<br/>*/}
              <ItemContainter>
                <img src={goldcheckicon} alt='Checked' />
                <Text content> 100% Gratuito</Text>
              </ItemContainter>
              <ItemContainter>
                <img src={goldcheckicon} alt='Checked' />
                <Text content> Alavanque sua Banca</Text>
              </ItemContainter>
              <ItemContainter>
                <img src={goldcheckicon} alt='Checked' />
                <Text content> Empresa com os melhores do Brasil</Text>
              </ItemContainter>
              <ItemContainter>
                <img src={goldcheckicon} alt='Checked' />
                <Text content> Método Vencedor</Text>
              </ItemContainter>
            </section>
            <div id="middle" />
            <RegisterContainer>
              {step === 1 && (
                <RegisterWrap>
                  <div style={{marginBottom: 32}}>
                    <RegisterTitle>Faça agora seu cadastro!</RegisterTitle>
                    <RegisterSubTitle>Preencha em segundos.</RegisterSubTitle>
                  </div>
                  <FieldContainerColumn>
                    <Label>Nome Completo:</Label>
                  </FieldContainerColumn>
                  <Input
                      autoComplete="off"
                      required={true}
                      placeholder='Nome'
                      type='text'
                      name='nome'
                      onChange={(e) => setNome(e.target.value)}
                  />
                  {/*<FieldContainerColumn>*/}
                  {/*  <Label>Sobrenome:</Label>*/}
                  {/*</FieldContainerColumn>*/}
                  {/*<Input*/}
                  {/*    autoComplete="off"*/}
                  {/*    required={true}*/}
                  {/*    placeholder='Sobrenome'*/}
                  {/*    type='text'*/}
                  {/*    name='sobrenome'*/}
                  {/*    onChange={(e) => setSobrenome(e.target.value)}*/}
                  {/*/>*/}
                  <FieldContainerColumn>
                    <Label>Seu melhor E-mail</Label>
                  </FieldContainerColumn>
                  <Input
                    autoComplete="off"
                    required={true}
                    placeholder='E-MAIL'
                    type='text'
                    name='email'
                    onChange={(e) => emailHandle(e.target.value)}
                  />
                  <FieldContainerColumn>
                    <Label>CPF</Label>
                  </FieldContainerColumn>
                  <Input
                      autoComplete="off"
                      required={true}
                      placeholder='CPF'
                      type='text'
                      name='cpf'
                      onChange={(e) => cpfHandle(e.target.value)}
                  />
                  <p style={{color: 'red', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '700'}}>{validationError}</p>
                  <FieldContainerColumn>
                    <Label>Número de Celular</Label>
                    <FieldContainerRow>
                      <Select placeholder='DDI' ddi>
                        <option value='55'>+55</option>
                      </Select>
                      <span />
                      <Input
                        autoComplete="off"
                        required={true}
                        name='ddi'
                        placeholder='NÚMERO COM DDD'
                        onChange={(e) => phoneHandle(e.target.value)}
                        type='number'
                      />
                    </FieldContainerRow>
                  </FieldContainerColumn>
                  <p style={{color: 'red', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '700'}}>{erro}</p>
                  {
                    !loading ?
                      <RegisterButton type='submit'>
                        CADASTRAR AGORA
                      </RegisterButton> :
                      <RegisterButton type='submit'>
                        <CircularProgress />
                      </RegisterButton>
                  }
                  <StepBar />
                </RegisterWrap>
              )}
              {step === 2 && (
                <RegisterWrap>
                  <RegisterTitle>Agora cadastre-se na Estrela Bet</RegisterTitle>
                  <br/><br/>
                  <TextoCadastro>
                    Faça seu cadastro , todas as entradas sao pela Estrela Bet, não perca tempo.
                  </TextoCadastro>
                  <br/>
                  <RegisterButtonGray type='button' onClick={() => {window.open('https://go.aff.estrelabetpartners.com/fnznv4oh'); handleNextStep(2)}}>
                    CADASTRAR NA ESTRELA BET
                  </RegisterButtonGray>
                  {/*<RegisterButton onClick={() => {window.open(linkTelegram)}}>*/}
                  {/*  Continuar*/}
                  {/*</RegisterButton>*/}
                </RegisterWrap>
              )}
              {step === 3 && (
                <RegisterWrap>
                  <RegisterTitle>Parabéns, você está participando do grupo do Telegram.</RegisterTitle>
                  <br/><br/>
                  {/*<TextoCadastro>*/}
                  {/*  Aproveite as tips!*/}
                  {/*</TextoCadastro>*/}

                  <RegisterButton onClick={() => {window.open(linkTelegram); handleNextStep(2)}}>
                    Ir para o Grupo
                  </RegisterButton>
                </RegisterWrap>
              )}
              <img style={{marginTop: '20px'}} src={require(`../../assets/${step}.png`)} alt='step' />
            </RegisterContainer>
          </form>
        </Content>
    </Wrapper>
  );
};

export default LandingPage;
