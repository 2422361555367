import styled from 'styled-components';
import HeaderSmallBG from '../../assets/fundoverdesmall.png';
import HeaderBigBG from '../../assets/fundoverdebg.png';
import BgSmall from '../../assets/fundoRegistro.png';
import BgBig from '../../assets/fundoRegistro.png';

type Props = {
  content?: boolean;
  ddi?: boolean;
  err?: boolean;
};

export const HeaderDiv = styled.div `
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const TextoCadastro = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #C9D5E1;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('${HeaderSmallBG}');
  background-size: cover;
  background-color: black;
  min-width: 100%;
  height: 130vh;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  img {
    margin-top: 74px;
    width: 160px;
    height: 154px;
  }
  @media only screen and (min-width: 1023px) {
    background-image: url('${HeaderBigBG}');

    img {
      margin-top: 117px;
      width: 188.73px;
      height: 188.73px;
    }
  }
`;

export const Title = styled.div<Props>`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-size: ${(props) => (props.content ? '40px' : '40px')};
  line-height: 100%;
  text-align: ${(props) => (props.content ? 'left' : 'center ')};
  text-transform: uppercase;
  text-justify: justify;
  color: #ffffff;
  margin-top: ${(props) => (props.content ? '40px' : '0px')};
  padding: 0 30px 0 30px;

  span {
  color:  #48FF4A;
  }

  @media only screen and (max-width: 1023px) {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
    text-transform: uppercase;
  }
`;
export const Text = styled.div<Props>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  color: #FFFFFF;
  margin-top: ${(props) => (props.content ? '-11px' : '0')};

  padding: ${(props) => (props.content ? '0 0 0 9px' : ' 0 32px 0 32px')};
  
  b{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
  }
  
  span {
    color:  #48FF4A;
  }

  @media only screen and (max-width: 1023px) {
    margin-top: ${(props) => (props.content ? '-2px' : '0')};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    
    b {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: #FFFFFF;
    }
  }
`;

export const ButtonStart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  background: linear-gradient(180deg, #017001 0%, #006001 100%);
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.2);
  border-radius: 37px;
  cursor: pointer;
  width: 296px;
  height: 56px;
  margin: 28px 32px 118px 32px;

  @media only screen and (min-width: 1023px) {
    width: 466px;
    height: 74px;
  }
`;
export const Content = styled.div<Props>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -100px;
  background: rgb(1,34,1);
  height: 100%;
  background-image: url('${BgBig}');
  background-size: cover;
  justify-content: center;

  form {
    display: flex;
    max-width: 1000px;
  }

  @media only screen and (max-width: 1023px) {
    flex-direction: row;
    justify-content: center;
    background-image: url('${BgSmall}');

    section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 40px;
    }

    form {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
`;
export const ItemContainter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 32px 0 32px;
  margin-top: 20px;
  img {
    width: 21px;
    height: 21px;
  }
`;
export const RegisterContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 696px;
  flex-direction: column;
  align-items: center;
  background: #212334;
  margin: 0 16px 0 16px;
  padding: 32px 16px 32px 16px;
  background: #005800;
  // justify-content: center;
  // align-content: center;

`;
export const RegisterTitle = styled.div`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  color: #FFFFFF;
  
  span {
    color: #48FF4A;
  }
`;
export const RegisterSubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #D7E8D7;
`;
export const FieldContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  color: #fff;
  background: rgba(45, 69, 95, 1);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  padding: 21px;
`;
export const FieldContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    width: 12px;
  }
`;
export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  span {
    width: 12px;
  }
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
`;

export const Select = styled.select<Props>`
  min-width: 60px;
  display: flex;
  flex-direction: row;
  padding-left: ${(props) => (props.ddi ? '10px' : '20px')};
  height: 52px;
  width: ${(props) => (props.ddi ? '20%' : '100%')};
  margin-bottom: 16px;
  background: rgba(46, 47, 56, 0.8);
  border: 1px solid #011500;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  color: #FFF;
`;

export const RegisterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Label = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #c9d5e1;
  margin-bottom: 8px;
  color: #ffffff;
`;
export const Input = styled.input<Props>`
  display: flex;
  width: 100%;
  height: 52px;
  background: rgba(46, 47, 56, 0.8);
  border: 1px solid #011500;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  box-sizing: border-box;
  color: #ffffff;
  padding-left: 20px;
  margin-bottom: 16px;
  ::placeholder {
    color: #FFFFFF50;
  }
  .checkbox {
    width: 10%;
  }
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
`;
export const Checkbox = styled.input`
  display: flex;
  width: 20px;
  height: 52px;
  background: rgba(46, 47, 56, 0.8);
  border: 1px solid #011500;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  box-sizing: border-box;
  color: #ffffff;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 16px;
  margin-right: 20px;
  ::placeholder {
    color: #FFFFFF50;
  }
  span {
    color: #00b1ff;
    margin: 0;
    padding: 0;
  }
`;
export const RegisterButton = styled.button`
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(180deg, #017001 0%, #006001 100%);

  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  border: none;
  margin: 12px 0 12px 0;
  cursor: pointer;
  text-transform: uppercase;
`;
export const RegisterButtonGray = styled.button`
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg,#017001 0%,#006001 100%);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  border: none;
  margin: 12px 0 12px 0;
  cursor: pointer;
  text-transform: uppercase;
`;
export const Value = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  width: fit-content;
  color: #ffffff;
`;
export const Discount = styled.div`
  display: flex;
  flex-direction: row;
  color: #6885a3;
  font-family: 'Montserrat';
  font-style: normal;
  margin-right: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  span {
    color: #f33c59;
  }
`;
export const StepBar = styled.div`
  display: flex;
  align-items: center;
`;
export const Step = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  text-justify: justify;
  font-size: 12px;
  line-height: 150%;
  color: #767c82;
  background: #1b2127;
  .titleFooter {
    margin-bottom: 13px;
    align-self: flex-start;
    padding: 32px 32px 0 32px;
  }
  .line {
    height: 1px;
    width: 95vw;
    background: #767c82;
    margin-top: 21px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  font-family: 'Montserrat';
  padding: 0 32px;
  font-style: normal;
  font-weight: 400;
  text-justify: justify;
  font-size: 12px;
  line-height: 150%;
  color: #767c82;

  @media only screen and (min-width: 1023px) {
    flex-direction: row;
    justify-content: center;
  }
`;
