import axios from 'axios';

class ActiveHostedService {
    CreateContact(contact) {
        return axios.post('https://a2.lineragames.com.br/Contacts2', {
            contact
        });
    }
}

export default new ActiveHostedService();
